@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-text {
  @apply text-center;
  background: linear-gradient(94deg, #2B82EB 18.47%, #69CFFE 53.35%, #00D0DD 97.26%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

body {
  background-color: #000300;
}

.newsletter {
  background-color: aliceblue;
  padding: 6rem
}

.community-section {
  background-color: white;
  padding: 8rem
}